import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import * as styles from './contact.module.css'
import { responsiveTitle3, responsiveTitle4 } from './../typography.module.css'

const query = graphql`
  query socialLinks {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      facebook
      instagram
      whatsapp
      youtube
      twitter
    }
  }
`

import { SiFacebook, SiInstagram, SiWhatsapp, SiYoutube, SiTwitter } from 'react-icons/si'
import { TiSocialAtCircular } from 'react-icons/ti'

const Contact = props => {
  const socialLinks = useStaticQuery(query)
  const { facebook, instagram, whatsapp, youtube, twitter } = socialLinks.site || {}
  return (
    <div className={styles.contact}>
      <form
        name="contact"
        method="POST"
        data-netlify="true"
        netlify-honeypot="bot-field"
        style={{ opacity: '.85', maxWidth: '30em', margin: '0 auto' }}
      >
        <legend className={responsiveTitle3} style={{ textAlign: 'center' }}>
          نسعد بتواصلك
        </legend>
        <fieldset>
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />

          <div className={styles.inlineInputs}>
            <label style={{ flex: '1', minWidth: '9em' }} htmlFor="name">
              <hr />
              الإسم:
              <input name="name" required type="text"></input>
            </label>
            <label style={{ flex: '1', minWidth: '9em' }} htmlFor="email">
              <hr />
              البريد الإلكتروني:
              <input name="email" type="email" required></input>
            </label>
          </div>
          <hr />
          <textarea name="message" placeholder="رسالتك هنا ..." rows="5"></textarea>
          <hr />
          <div style={{ display: 'flex' }}>
            <button type="submit" style={{ marginRight: 'auto' }}>
              أرسل
            </button>
          </div>
        </fieldset>
      </form>
      <div className={styles.social}>
        <h4 className={responsiveTitle4}>أو راسلنا عبر الإميل</h4>
        <address>
          <a href="mailto:qasastro@gmail.com">
            <span>
              <TiSocialAtCircular size="2.4em" className={styles.contactIcon} />
            </span>
            <span>qasastro@gmail.com</span>
          </a>
        </address>
      </div>
      <div className={styles.social}>
        <h4 className={responsiveTitle4}>
          {' '}
          لكي تصلكم رسائل الجمعية على الواتساب يمكن حفظ رقم الجمعية أدناه وإرسال رسالة بكلمة
          (إشتراك)
        </h4>
        <address>
          <a data-icon="whatsapp" href={whatsapp}>
            <span>
              <SiWhatsapp size="1.8em" className={styles.contactIcon} />
            </span>
            <span>+966567644728</span>
          </a>
        </address>
      </div>
      <div className={styles.social}>
        <h4 className={responsiveTitle4}>تابعنا عبر قنواتنا</h4>
        <section>
          <a href={facebook}>
            <SiFacebook size="3.5em" className={styles.socialIcon} />
          </a>
          <a href={instagram}>
            <SiInstagram size="3.5em" className={styles.socialIcon} />
          </a>
          <a href={youtube}>
            <SiYoutube size="3.5em" className={styles.socialIcon} />
          </a>
          <a href={twitter}>
            <SiTwitter size="3.5em" className={styles.socialIcon} />{' '}
          </a>
        </section>
      </div>
      <hr />
      <hr />
      <hr />
    </div>
  )
}

export default Contact
