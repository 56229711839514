import React from 'react'
import Layout from '../containers/layout'
import Container from '../components/container'
import Contact from '../components/pages/contact'
import SEO from '../components/seo'
import Breadcrumb from '../components/layout/breadcrumb'

const CalendarPage = props => {
  let navItemStack = [{ name: 'تواصل معنا', slug: 'contact' }]

  return (
    <Layout>
      <Container>
        <Breadcrumb currentNavItems={navItemStack} />
        <hr></hr>
        <hr></hr>
        <Contact />
      </Container>
    </Layout>
  )
}
export default CalendarPage

export const Head = ({ location }) => (
  <SEO title="تواصل معنا" pathname={location.pathname}></SEO>
)
