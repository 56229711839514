import React from 'react'

import * as styles from './container.module.css'
import '../styles/layout.css'

const Container = ({ children, style }) => {
  return (
    <div className={styles.root} style={style}>
      {children}
    </div>
  )
}

export default Container
